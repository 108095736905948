// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import Box from "@mui/material/Box";
// import IconButton from "@mui/material/IconButton";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Skeleton from '@mui/material/Skeleton';
// import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import relativeTime from "dayjs/plugin/relativeTime";
// import { Pagination } from "@mui/lab";
// import {
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   Button,
//   ButtonBase,
//   ButtonGroup,
//   CircularProgress,
//   Grid,
//   LinearProgress,
//   Link,
//   Stack,
//   TablePagination,
//   Tooltip,
//   Checkbox,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// import ReactCountryFlag from "react-country-flag";
// import KeywordLimit from "src/components/keywordsLimit/limitKeywords";
// import Iconify from "../iconify/Iconify";
// import KeywordHistory from "../keyword-history/keywordHistory";
// import { MyContext } from "../../pages/DashboardAppPage";
// import domain from "../../module/domain";
// import keyword from "../../module/keyword";
// import position from "../../module/position";
// import DownloadButton from "../csv-download";
// import AddKeyword from "../keyword/addKeyword";
// import Auth from "../../module/Auth";
// import "./collapse.css";






// function createData(keyword, position, id, favourite, code) {
//   return {
//     keyword,
//     position,
//     id,
//     favourite,
//     code,
//   };
// }

// function Row(props) {
//   const {
//     row,
//     selectedRows,
//     handleCheckboxChange,
//     setDeleted,
//     deleted,
//     setKeywordData,
//     keywordData,
//     domainName,
//     setCount,
//     setRefresh,
//   } = props;
//   const [open, setOpen] = React.useState(false);
//   const [rowData, setRowData] = React.useState(props?.row);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);
//   const [loading, setLoading] = React.useState(false);
//   const [sortDirection, setSortDirection] = React.useState("asc");
//   const [filter , setFilter] = React.useState('all');







//   useEffect(() => {}, [rowData, keywordData]);



  
//   const handleReload = (id) => {
//     if (!isCd) {
//       setLoading(true);
//       position.refreshPosition(id, (response) => {
//         if (response.status === "success") {
//           const data = {
//             code: response.data?.code,
//             favourite: response.data?.favourite,
//             id: response.data?.id,
//             keyword: response.data?.keyword,
//             position: response.data?.position,
//           };
//           setRowData(data);
//           setKeywordData((prev) => [...prev]);
//           setRefresh((prev) => !prev);
//           // if (open) {
//           //   handleOtherLinksData(response.data?.id);
//           // }

//           setLoading(false);
//         }
//         setLoading(false);
//       });
//     }
//   };

//   const handleDelete = (rowId) => {
//     if (!isCd) {
//       keyword.deleteKeyword(rowId, (response) => {
//         if (response.status === "success") {
//           setDeleted(!deleted);
//           setKeywordData((prevKeywordData) =>
//             prevKeywordData.filter((row) => row.id !== rowId)
//           );
//         }
//       });
//     }
//   };

//   // const handleAlert = (id) => {
//   //   if (Auth?.isSubscribed()) {
//   //     window.alert("Delete option is not available for free account");
//   //   } else {
//   //     const res = window.confirm(
//   //       "Are you sure you want to delete this keyword?"
//   //     );

//   //     handleDelete(id);
//   //   }
//   // };


//   const handleAlert = (id) => {
//     if (!Auth?.isSubscribed()) {
//       window.alert("Delete option is not available for free account");
//     } else {
//       const res = window.confirm(
//         "Are you sure you want to delete this keyword?"
//       );
//       if (res) {
//         handleDelete(id);
//       }
//     }
//   };


//   const handleFavorite = (id, param) => {
//     if (!isCd) {
//       let fav;
//       if (param === "yes") {
//         fav = true;
//       } else {
//         fav = false;
//       }
//       const data = {
//         favourite: fav,
//       };
//       keyword.updateKeyword(id, data, (response) => {
//         setLoading(true);
//         if (response.status === "success") {
//           setRowData({ ...rowData, favourite: response.data.favourite });
//           setLoading(false);
//         }
//         setLoading(false);
//       });
//     }
//   };
//   const handleOpen = () => {
//     setOpen(!open);
//   };

//   dayjs.extend(utc);
//   dayjs.extend(timezone);
//   dayjs.extend(relativeTime);

//   // Get the user's local time zone
//   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   // Use the `utc` method to parse the ISO timestamp string and convert it to UTC time
//   const utcTime = dayjs.utc(rowData?.position?.creationDate);

//   // Use the `tz` method to convert the UTC time to the user's local time zone
//   const localTime = utcTime.tz(userTimeZone);

//   const handleEye = () => {
//     if (rowData?.position?.url === null) {
//       setOpen(false);
//     } else {
//       setOpen(true);
//     }
//   };

//   const isCd = Auth.getIsCd(); //


//   React.useEffect(() => {
//     console.log('Debug Status in CollapsibleTable:');
//     console.log('Is Subscribed:', Auth.isSubscribed());
//     console.log('Is Free:', Auth.getIsFree());
//   }, []);


//   return (
  
//       <>
//         {/* <Item> */}
//         <TableRow className="table-row" key={rowData?.id}>
//           <TableCell className="t1" key={rowData?.id}>
//             <Box display={"flex"} flexDirection={"row"} m={0} p={0}>
//             {Auth?.isSubscribed() && !isCd && (
//                 <Checkbox
//                   checked={selectedRows?.includes(row?.id)}
//                   onChange={(event) => handleCheckboxChange(event, row?.id)}
//                   sx={{ 
//                     '&.Mui-checked': {
//                       color: '#00A3D8',
//                     }
//                   }}
//                 />
//               )}
//               <IconButton
//                 key={rowData?.id}
//                 aria-label="expand row"
//                 size="small"
//               >
//                 <Iconify
//                   onClick={handleEye}
//                   icon={"mdi:eye"}
//                   width={16}
//                   height={16}
//                 />
//               </IconButton>
//             </Box>

//             {open ? (
//               <KeywordHistory
//                 rowData={rowData}
//                 position={rowData?.position?.position}
//                 // chartData={chartData}
//                 // otherLinks={otherLinks}
//                 open={open}
//                 handlePropClose={handleOpen}
//               />
//             ) : null}
//           </TableCell>
//           <TableCell
//             scope="row"
//             key={rowData?.id}
//             sx={{ maxWidth: "500px", overflow: "clip" }}
//           >
//             <Link
//               target={"_blank"}
//               rel="noopener nofollow"
//               href={rowData?.position?.url}
//             >
//               {rowData?.position?.url}
//             </Link>
//           </TableCell>
//           <TableCell table-row align="right">
//             {
//               <>
//                 {rowData?.keyword} &nbsp;
//                 <ReactCountryFlag
//                   countryCode={rowData.code}
//                   svg
//                   cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
//                   cdnSuffix="svg"
//                   title="flag"
//                 />
//               </>
//             }
//           </TableCell>
//           <TableCell
//             className="table-row"
//             align="right"
//             style={{
//               color:
//                 rowData?.position == null || rowData?.position?.position == null
//                   ? "gray"
//                   : "black",
//             }}
//           >
//             {!loading ? (
//               <>
//                 {rowData?.position !== null &&
//                 rowData?.position?.position !== null
//                   ? rowData?.position?.position
//                   : ">100"}
//               </>
//             ) : (
//               <LinearProgress />
//             )}
//           </TableCell>
//           <TableCell align="right" key={rowData?.id}>
//             {!loading ? (
//               <>
//                 {rowData.position?.dayStats != null &&
//                 rowData.position?.dayStats !== 0 ? (
//                   <>
//                     {rowData?.position?.dayStats.toString().replace(/^-/, "")}{" "}
//                     {Math.sign(rowData?.position?.dayStats) === 1 ? (
//                       <Tooltip title="Going Good">
//                         <Iconify
//                           icon={"ant-design:caret-up-filled"}
//                           color={"green"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     ) : (
//                       <Tooltip title="Down">
//                         <Iconify
//                           icon={"ant-design:caret-down-filled"}
//                           color={"red"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     )}
//                   </>
//                 ) : (
//                   "-"
//                 )}
//               </>
//             ) : (
//               <LinearProgress />
//             )}
//           </TableCell>
//           <TableCell align="right" key={rowData?.id}>
//             {!loading ? (
//               <>
//                 {rowData.position?.weekStats != null &&
//                 rowData.position?.weekStats !== 0 ? (
//                   <>
//                     {rowData?.position?.weekStats.toString().replace(/^-/, "")}{" "}
//                     {Math.sign(rowData?.position?.weekStats) === 1 ? (
//                       <Tooltip title="Going Good">
//                         <Iconify
//                           icon={"ant-design:caret-up-filled"}
//                           color={"green"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     ) : (
//                       <Tooltip title="Down">
//                         <Iconify
//                           icon={"ant-design:caret-down-filled"}
//                           color={"red"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     )}
//                   </>
//                 ) : (
//                   "-"
//                 )}
//               </>
//             ) : (
//               <LinearProgress />
//             )}
//           </TableCell>
//           <TableCell align="right" key={rowData?.id}>
//             {!loading ? (
//               <>
//                 {rowData.position?.monthStats != null &&
//                 rowData.position?.monthStats !== 0 ? (
//                   <>
//                     {rowData?.position?.monthStats.toString().replace(/^-/, "")}{" "}
//                     {Math.sign(rowData?.position?.monthStats) === 1 ? (
//                       <Tooltip title="Going Good">
//                         <Iconify
//                           icon={"ant-design:caret-up-filled"}
//                           color={"green"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     ) : (
//                       <Tooltip title="Down">
//                         <Iconify
//                           icon={"ant-design:caret-down-filled"}
//                           color={"red"}
//                           width={14}
//                           height={10}
//                         />
//                       </Tooltip>
//                     )}
//                   </>
//                 ) : (
//                   "-"
//                 )}
//               </>
//             ) : (
//               <LinearProgress />
//             )}
//           </TableCell>
//           <TableCell className="action" align="right" key={rowData?.id}>
//             <Box display={"flex"} flexDirection={"row"} marginLeft={3}>
//               {rowData?.favourite ? (
//                 <>
//                   <Tooltip title="Favorite">
//                     <Iconify
//                       icon={"uim:favorite"}
//                       width={20}
//                       height={18}
//                       sx={{
//                         cursor: "pointer",
//                       }}
//                       onClick={() => handleFavorite(rowData?.id, "no")}
//                     />
//                   </Tooltip>
//                 </>
//               ) : (
//                 <>
//                   <Tooltip title="Add Favorite">
//                     <Iconify
//                       icon={"mi:favorite"}
//                       width={20}
//                       height={18}
//                       sx={{
//                         cursor: "pointer",
//                       }}
//                       onClick={() => handleFavorite(rowData?.id, "yes")}
//                     />
//                   </Tooltip>
//                 </>
//               )}

//               <Tooltip title={"Refresh"}>
//                 <Iconify
//                   icon={"ion:reload-circle"}
//                   width={20}
//                   height={18}
//                   sx={{
//                     cursor: "pointer",
//                   }}
//                   onClick={() => handleReload(rowData?.id)}
//                 />
//               </Tooltip>
//               {!isCd && (
                
//                 <Tooltip
//                 title={
//                   !Auth?.isSubscribed()
//                     ? "Delete option is disabled for free account"
//                     : "Delete"
//                 }
//                 placement="top"
//               >
//                 <Iconify
//                   icon={"ic:baseline-delete"}
//                   width={20}
//                   height={18}
//                   sx={{
//                     cursor: "pointer",
//                     color: !Auth?.isSubscribed() ? "gray" : "#ff0000bf",
//                   }}
//                   onClick={() => handleAlert(rowData?.id)}
//                 />
//               </Tooltip>
//               )}
//             </Box>
//           </TableCell>

//           <TableCell
//             className="table-row"
//             align="right"
//             key={rowData?.position?.id}
//           >
//             <Typography
//               align="center"
//               variant="body2"
//               color="textSecondary"
//               component="p"
//             >
//               {rowData?.position !== null &&
//               rowData?.position?.position !== null
//                 ? localTime.fromNow({ includeSeconds: true })
//                 : "-"}
//             </Typography>
//           </TableCell>
//         </TableRow>
//         {/* </Item> */}
//       </>
    
//   );
// }






// Row.propTypes = {
//   row: PropTypes.shape({
//     keyword: PropTypes.string.isRequired,
//     position: PropTypes.object.isRequired,
//     id: PropTypes.string.isRequired,
//     favourite: PropTypes.string.isRequired,
//     code: PropTypes.string.isRequired,
//   }).isRequired,
// };

// export default function CollapsibleTable() {
//   const {
//     domainName,
//     tableLoading,
//     keywordData,
//     setKeywordData,
//     refresh,
//     setRefresh,
//   } = React.useContext(MyContext);
//   const [rowsPerPage, setRowsPerPage] = React.useState(30);
//   const [page, setPage] = React.useState(0);
//   const [deleted, setDeleted] = React.useState(false);
//   const [loading, setLoading] = React.useState(false);
//   const [count, setCount] = React.useState(0);
//   const [deviceName, setDeviceName] = React.useState("desktop");
//   const [selected, setSelected] = React.useState(true);
//   const [mobileSelected, setMobileSelected] = React.useState(false);
//   const [desktopSelected, setDesktopSelected] = React.useState(false);
//   const [selectedRows, setSelectedRows] = React.useState([]);
//   const [checked, setChecked] = React.useState(false);
//   const [rows, setRows] = React.useState([]);
//   const [sortAscending, setSortAscending] = React.useState(true);

//   React.useEffect(() => {
//     if (domainName?.id) getDomainById(page, deviceName);
//   }, [
//     domainName,
//     tableLoading,
//     rowsPerPage,
//     page,
//     deviceName,
//     deleted,
//     refresh,
//   ]);

  




// // Add these styled components at the top
// const StyledCard = styled(Paper)(({ theme }) => ({
//   borderRadius: theme.spacing(2),
//   backgroundColor: '#fff',
//   boxShadow: '0 8px 40px rgba(118, 149, 255, 0.1)',
//   transition: 'transform 0.2s ease-in-out',
//   '&:hover': {
//     transform: 'translateY(-4px)',
//     boxShadow: '0 12px 48px rgba(118, 149, 255, 0.15)',
//   },
// }));

// const StyledFilterBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   gap: theme.spacing(2),
//   alignItems: 'center',
//   flexWrap: 'wrap',
// }));

// const StyledFormControl = styled(FormControl)(({ theme }) => ({
//   minWidth: 120,
//   '& .MuiOutlinedInput-root': {
//     borderRadius: theme.spacing(1.5),
//     backgroundColor: '#F8F9FF',
//     '&.Mui-focused': {
//       backgroundColor: '#fff',
//     },
//     '& fieldset': {
//       borderColor: 'rgba(118, 149, 255, 0.1)',
//     },
//     '&:hover fieldset': {
//       borderColor: '#7695FF',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#7695FF',
//     },
//   },
// }));


// const StyledActionButton = styled(Button)(({ theme }) => ({
//   borderRadius: theme.spacing(1),
//   padding: '6px 16px',
//   textTransform: 'none',
//   fontWeight: 500,
//   display: 'flex',
//   alignItems: 'center',
//   gap: '8px',
//   boxShadow: 'none',
//   color: '#637381',
//   backgroundColor: '#F8F9FF',
//   border: '1px solid rgba(145, 158, 171, 0.32)',
//   '&:hover': {
//     backgroundColor: '#F4F6F8',
//     boxShadow: 'none',
//   },
//   '& .MuiButton-startIcon': {
//     margin: 0,
//   },
// }));

// const StyledIconify = styled(Iconify)(({ theme }) => ({
//   width: 22,
//   height: 22,
//   transition: 'transform 0.2s ease-in-out',
//   '&:hover': {
//     transform: 'scale(1.1)',
//   },
// }));

// const [filters, setFilters] = React.useState({
//   dateSort: 'none',
//   positionSort: 'none',
//   favoriteFilter: 'all',
//   device: 'desktop' // Add device to filters
// });


// // Add filter handlers in CollapsibleTable
// const handleFilterChange = (filterType, value) => {
//   setFilters(prev => ({
//     ...prev,
//     [filterType]: value
//   }));
// };

// const handleResetFilters = () => {
//   setFilters({
//     dateSort: 'none',
//     positionSort: 'none',
//     favoriteFilter: 'all'
//   });
// };

// // Modify your existing sort function to work with filters
// const sortData = () => {
//   let sortedData = [...keywordData];

//   // Apply position sorting
//   if (filters.positionSort !== 'none') {
//     sortedData.sort((a, b) => {
//       const positionA = a.position?.position || Infinity;
//       const positionB = b.position?.position || Infinity;
//       return filters.positionSort === 'asc' ? positionA - positionB : positionB - positionA;
//     });
//   }

//   // Apply date sorting
//   if (filters.dateSort !== 'none') {
//     sortedData.sort((a, b) => {
//       const dateA = new Date(a.position?.creationDate || 0);
//       const dateB = new Date(b.position?.creationDate || 0);
//       return filters.dateSort === 'newest' ? dateB - dateA : dateA - dateB;
//     });
//   }

//   // Apply favorite filter
//   if (filters.favoriteFilter !== 'all') {
//     sortedData = sortedData.filter(item => 
//       filters.favoriteFilter === 'favorite' ? item.favourite : !item.favourite
//     );
//   }

//   setKeywordData(sortedData);
// };

// // Update useEffect to trigger sorting when filters change
// React.useEffect(() => {
//   if (keywordData?.length) {
//     sortData();
//   }
// }, [filters]);






//   const getDomainById = (pagenum, deviceName) => {
//     setLoading(true);

//     domain.getByDomainId(
//       domainName?.id,
//       page,
//       rowsPerPage,
//       deviceName,
//       (response) => {
//         if (response.status === "success") {
//           const totalRows = response.data.totalRows;
//           const rows = response.data.keywordDetails?.map((item) => {
//             return createData(
//               item?.keyword,
//               item?.position,
//               item?.id,
//               item?.favourite,
//               item?.code
//             );
//           });

//           setCount(totalRows);
//           setKeywordData(rows);

//           setTimeout(() => {
//             setLoading(false);
//           }, 500);
//         } else {
//           setLoading(false);

//           setKeywordData([]);
//         }
//       }
//     );
//   };

//   const startIndex = page * rowsPerPage;
//   const endIndex = (page + 1) * rowsPerPage;

//   const handleSortClick = () => {
//     setSortAscending(!sortAscending);
//     sortData();
//   };

//   // const sortData = () => {
//   //   const sortedData = [...keywordData].sort((a, b) => {
//   //     const positionA = a.position ? a.position.position : null;
//   //     const positionB = b.position ? b.position.position : null;

//   //     if (positionA === null && positionB === null) {
//   //       return 0;
//   //     }
//   //     if (positionA === null) {
//   //       return 1;
//   //     }
//   //     if (positionB === null) {
//   //       return -1;
//   //     }
//   //     if (sortAscending) {
//   //       return positionA - positionB;
//   //     } else {
//   //       return positionB - positionA;
//   //     }
//   //   });
//   //   setKeywordData(sortedData);

//   //   const filteredData = sortedData?.slice(startIndex, endIndex);
//   // };

//   // const totalRows = React.useMemo(() => getDomainById(page, deviceName), [page, deviceName]);
//   // console.log(totalRows);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     const newRowsPerPage = parseInt(event.target.value, 10);
//     const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage); // Calculate the new page based on the updated rowsPerPage.

//     setRowsPerPage(newRowsPerPage);
//     setPage(newPage);
//   };

//   const handleRemove = () => {
//     setDeleted(!deleted);
//   };

//   // const handleDeleteAllAlert = () => {
//   //   if (Auth?.isSubscribed()) {
//   //     window.alert("Delete option is not available for free account");
//   //   } else {
//   //     const res = confirm("Are you sure, you want to delete all keywords");
//   //     if (res) {
//   //       handleDeleteAll();
//   //     }
//   //   }
//   // };

//   const handleDeleteAllAlert = () => {
//     if (!Auth?.isSubscribed()) {
//       window.alert("Delete option is not available for free account");
//     } else {
//       const res = confirm("Are you sure you want to delete all keywords");
//       if (res) {
//         handleDeleteAll();
//       }
//     }
//   };

//   const handleDeleteAll = () => {
//     setLoading(true);
//     keyword.deleteAllKeywords(domainName?.id, (response) => {
//       if (response.status === "success") {
//         setKeywordData([]);
//         getDomainById(0, "desktop");
//       }
//     });
//     setLoading(false);
//   };

//   const handleRefreshAll = () => {
//     if (!isCd) {
//       setLoading(true);
//       const ids = keywordData.map((data) => data.id);
//       const chunkSize = 20; // Number of IDs to send per API request
//       const totalChunks = Math.ceil(ids.length / chunkSize); // Total number of chunks

//       const sendChunk = (chunkIndex) => {
//         const start = chunkIndex * chunkSize;
//         const end = start + chunkSize;
//         const chunkIds = ids.slice(start, end);

//         try {
//           keyword.reloadAllKeywords(chunkIds, (response) => {
//             const data = response.data;
//             if (response.status === "success") {
//               setLoading(false);
//               // const totalRows = response.data.totalRows;
//               setRefresh(!refresh);
//               const updatedRows = response.data;

//               setKeywordData((prevData) => {
//                 // Update the keywordData by replacing the relevant chunks
//                 return prevData.map((item) => {
//                   if (updatedRows.find((row) => row.id === item.id)) {
//                     return updatedRows.find((row) => row.id === item.id);
//                   } else {
//                     return item;
//                   }
//                 });
//               });
//             }
//           });

//           //   const ro=   data?.map((item) => {
//           //        return createData(item?.keyword, item?.position, item?.id, item?.favourite, item?.code);
//           //      });
//           //      setKeywordData(ro);
//           //    }

//           //  });

//           // setKeywordData((prevData) => {
//           //   // Update the keywordData by replacing the relevant chunks
//           //   const newData = [...prevData];
//           //   chunkIds.forEach((id, index) => {
//           //     const dataIndex = start + index;
//           //     newData[dataIndex] = response.data.find((item) => item.id === id);
//           //   });

//           //   return newData;

//           // });

//           if (chunkIndex < totalChunks - 1) {
//             setTimeout(() => sendChunk(chunkIndex + 1), 1000); // Send the next chunk after a short delay
//           }
//         } catch (error) {
//           setLoading(false);
//         }
//       };

//       sendChunk(0);
//     }
//   };

//  // 3. Update handleDeviceChange to use filter system
//  const handleDeviceChange = (device) => {
//   handleFilterChange('device', device);
//   setDeviceName(device); // Keep this for API compatibility
// };


//   const handleSelectAll = (event) => {
//     if (event.target.checked) {
//       const newSelected = keywordData.map((row) => row.id);
//       setSelectedRows(newSelected);
//     } else {
//       selectedRows([]);
//     }
//   };

//   const handleCheckboxChange = (event, id) => {
//     if (event.target.checked) {
//       setSelectedRows([...selectedRows, id]);
//     } else {
//       setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
//     }
//   };

//   const handleDeleteSelectedAlert = () => {
//     const res = confirm("Are you sure, you want to delete selected keywords");
//     if (res) {
//       handleDeleteSelected(selectedRows);
//     }
//   };

//   const handleDeleteSelected = (selectedRows) => {
//     setLoading(true);

//     selectedRows.forEach((rowId) => {
//       keyword.deleteKeyword(rowId, (response) => {
//         if (response.status === "success") {
//           setDeleted(!deleted);
//           setKeywordData((prevKeywordData) =>
//             prevKeywordData.filter((row) => row.id !== rowId)
//           );
//         }
//       });
//     });

//     setSelectedRows([]);
//     setLoading(false);
//   };

//   const onSelect = (keywordId) => {
//     const isSelected = selectedRows.some((row) => row.id === keywordId);
//     let newSelected = [];

//     if (!isSelected) {
//       const selectedKeyword = keywordData.find((row) => row.id === keywordId);
//       newSelected = [...selectedRows, selectedKeyword];
//     } else {
//       newSelected = selectedRows.filter((row) => row.id !== keywordId);
//     }

//     setSelectedRows(newSelected);
//   };

//   const isCd = Auth.getIsCd(); //




//   const TableSkeleton = () => {
//     return (
//       <>
//         {[...Array(10)].map((_, index) => (
//           <TableRow key={index}>
//             <TableCell>
//               <Skeleton variant="rectangular" width={20} height={20} />
//             </TableCell>
//             <TableCell>
//               <Skeleton variant="text" width={250} />
//             </TableCell>
//             <TableCell align="right">
//               <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
//                 <Skeleton variant="text" width={150} />
//                 <Skeleton variant="circular" width={20} height={20} />
//               </Box>
//             </TableCell>
//             <TableCell align="right">
//               <Skeleton variant="text" width={50} />
//             </TableCell>
//             <TableCell align="right">
//               <Skeleton variant="text" width={30} />
//             </TableCell>
//             <TableCell align="right">
//               <Skeleton variant="text" width={30} />
//             </TableCell>
//             <TableCell align="right">
//               <Skeleton variant="text" width={30} />
//             </TableCell>
//             <TableCell align="center">
//               <Box display="flex" justifyContent="center" gap={1}>
//                 <Skeleton variant="circular" width={24} height={24} />
//                 <Skeleton variant="circular" width={24} height={24} />
//                 <Skeleton variant="circular" width={24} height={24} />
//               </Box>
//             </TableCell>
//             <TableCell align="center">
//               <Skeleton variant="text" width={80} />
//             </TableCell>
//           </TableRow>
//         ))}
//       </>
//     );
//   };


//   return (
//     <Box>
//       <Grid container alignItems="center" spacing={2}>
//         <Grid item>
//           <ButtonGroup variant="outlined" aria-label="split button">
//             <Tooltip title={"Device Desktop"}>
//               <Button
//                 onClick={() => handleDeviceChange("desktop")}
//                 variant={selected ? "contained" : "outlined"}
//                 color={selected ? "success" : "primary"}
//               >
//                 <Iconify icon={"material-symbols:desktop-mac-outline"} />
//               </Button>
//             </Tooltip>
//             <Tooltip title={"Device Mobile"}>
//               <Button
//                 variant={mobileSelected ? "contained" : "outlined"}
//                 onClick={() => handleDeviceChange("mobile")}
//                 color={mobileSelected ? "success" : "primary"}
//               >
//                 <Iconify icon={"mdi:mobile-phone"} />
//               </Button>
//             </Tooltip>
//           </ButtonGroup>
//           <Grid item display={"flex-start"}>
//             <KeywordLimit
//               AddKeyword={AddKeyword}
//               deleted={deleted}
//               loading={loading}
//               keywordData={keywordData}
//             />
//           </Grid>
//         </Grid>
//         {keywordData?.length > 0 && (
//           <Grid item xs>
//             <Grid
//               container
//               justifyContent="flex-end"
//               alignItems="center"
//               spacing={2}
//             >
//               {!isCd && (
//                 <Grid item>
//                   <Tooltip title={"Delete All"} placement="top">
//                     <Iconify
//                       icon={"ic:baseline-folder-delete"}
//                       width={25}
//                       height={25}
//                       sx={{
//                         cursor: "pointer",
//                         color: "#ff0000bf",
//                       }}
//                       onClick={() => handleDeleteAllAlert()}
//                     />
//                   </Tooltip>
//                 </Grid>
//               )}

//               <Grid item>
//                 <Tooltip title={"Refresh All"} placement="top">
//                   {!loading ? (
//                     <Iconify
//                       icon={"mdi:camera-refresh"}
//                       width={25}
//                       height={25}
//                       sx={{
//                         cursor: "pointer",
//                         color: "green",
//                       }}
//                       onClick={() => handleRefreshAll()}
//                     />
//                   ) : (
//                     <>
//                       <CircularProgress size={18} />
//                     </>
//                   )}
//                 </Tooltip>
//               </Grid>
//               {!isCd && !Auth?.isSubscribed() ? (
//                 <>
//                   <Grid item>
//                     <DownloadButton countSize={count} />
//                   </Grid>
//                 </>
//               ) : null}
//             </Grid>
//           </Grid>
//         )}
//       </Grid>

//        {/* New Filter Controls */}
//        <StyledCard>
//   <Box sx={{ p: 2 }}>
//     {/* Top Section - Filters and Action Buttons */}
//     <Box sx={{ 
//       display: 'flex', 
//       justifyContent: 'space-between',
//       alignItems: 'center',
//       flexWrap: 'wrap',
//       gap: 2 
//     }}>
//       {/* Left Side - Filters */}
//       <StyledFilterBox>
//         <StyledFormControl size="small">
//           <InputLabel>Device</InputLabel>
//           <Select
//             value={filters.device}
//             label="Device"
//             onChange={(e) => handleDeviceChange(e.target.value)}
//             startAdornment={
//               <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
//                 <Iconify 
//                   icon={filters.device === "desktop" ? "material-symbols:desktop-mac-outline" : "mdi:mobile-phone"} 
//                   width={20} 
//                   height={20}
//                 />
//               </Box>
//             }
//           >
//             <MenuItem value="desktop">Desktop</MenuItem>
//             <MenuItem value="mobile">Mobile</MenuItem>
//           </Select>
//         </StyledFormControl>

//         <StyledFormControl size="small">
//   <InputLabel>Date Sort</InputLabel>
//   <Select
//     value={filters.dateSort}
//     label="Date Sort"
//     onChange={(e) => handleFilterChange('dateSort', e.target.value)}
//     startAdornment={
//       <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
//         <StyledIconify 
//           icon="solar:calendar-bold" 
//           sx={{ color: '#637381' }}
//         />
//       </Box>
//     }
//   >
//     <MenuItem value="none">No Sort</MenuItem>
//     <MenuItem value="newest">Newest First</MenuItem>
//     <MenuItem value="oldest">Oldest First</MenuItem>
//   </Select>
// </StyledFormControl>

// <StyledFormControl size="small">
//   <InputLabel>Position</InputLabel>
//   <Select
//     value={filters.positionSort}
//     label="Position"
//     onChange={(e) => handleFilterChange('positionSort', e.target.value)}
//     startAdornment={
//       <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
//         <StyledIconify 
//           icon="solar:sort-from-bottom-to-top-bold" 
//           sx={{ color: '#637381' }}
//         />
//       </Box>
//     }
//   >
//     <MenuItem value="none">No Sort</MenuItem>
//     <MenuItem value="asc">Lowest First</MenuItem>
//     <MenuItem value="desc">Highest First</MenuItem>
//   </Select>
// </StyledFormControl>

// <StyledFormControl size="small">
//   <InputLabel>Favorites</InputLabel>
//   <Select
//     value={filters.favoriteFilter}
//     label="Favorites"
//     onChange={(e) => handleFilterChange('favoriteFilter', e.target.value)}
//     startAdornment={
//       <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
//         <StyledIconify 
//           icon="solar:star-bold" 
//           sx={{ color: '#637381' }}
//         />
//       </Box>
//     }
//   >
//     <MenuItem value="all">All Keywords</MenuItem>
//     <MenuItem value="favorite">Favorites Only</MenuItem>
//     <MenuItem value="notFavorite">Non-Favorites</MenuItem>
//   </Select>
// </StyledFormControl>

// {(filters.dateSort !== 'none' || 
//   filters.positionSort !== 'none' || 
//   filters.favoriteFilter !== 'all' ||
//   filters.device !== 'desktop') && (
//   <StyledActionButton
//     startIcon={
//       <StyledIconify 
//         icon="solar:filter-remove-bold" 
//         sx={{ color: '#7695FF' }}
//       />
//     }
//     onClick={handleResetFilters}
//     sx={{
//       color: '#7695FF',
//       '&:hover': {
//         backgroundColor: 'rgba(118, 149, 255, 0.08)',
//       }
//     }}
//   >
//     Clear
//   </StyledActionButton>
// )}
//       </StyledFilterBox>

//       {/* Right Side - Action Buttons */}
//       <Box sx={{ 
//         display: 'flex', 
//         gap: 1,
//         alignItems: 'center',
//         flexWrap: 'wrap'
//       }}>
//     {/* Action Buttons */}
// {keywordData?.length > 0 && !isCd && (
//   <Tooltip title={!Auth?.isSubscribed() ? "Delete option not available for free account" : "Delete All"}>
//     <span>
//       <StyledActionButton
//         startIcon={
//           <StyledIconify 
//             icon="solar:trash-bin-trash-bold" 
//             sx={{ color: !Auth?.isSubscribed() ? 'grey.400' : '#FF4842' }}
//           />
//         }
//         onClick={() => handleDeleteAllAlert()}
//         disabled={!Auth?.isSubscribed()}
//         sx={{
//           color: !Auth?.isSubscribed() ? 'grey.400' : '#FF4842',
//           '&:hover': {
//             backgroundColor: !Auth?.isSubscribed() ? '#F4F6F8' : 'rgba(255, 72, 66, 0.08)',
//           }
//         }}
//       >
//         Delete All
//       </StyledActionButton>
//     </span>
//   </Tooltip>
// )}

// {keywordData?.length > 0 && (
//   <Tooltip title="Refresh All">
//     <span>
//       <StyledActionButton
//         startIcon={!loading ? (
//           <StyledIconify 
//             icon="solar:refresh-circle-bold" 
//             sx={{ color: '#00AB55' }}
//           />
//         ) : (
//           <CircularProgress size={22} color="inherit" />
//         )}
//         onClick={() => handleRefreshAll()}
//         disabled={loading}
//         sx={{
//           color: '#00AB55',
//           '&:hover': {
//             backgroundColor: 'rgba(0, 171, 85, 0.08)',
//           }
//         }}
//       >
//         Refresh
//       </StyledActionButton>
//     </span>
//   </Tooltip>
// )}

// {/* {!isCd && !Auth?.isSubscribed() && (
//   <StyledActionButton
//     startIcon={
//       <StyledIconify 
//         icon="solar:download-square-bold" 
//         sx={{ color: '#00A3D8' }}
//       />
//     }
//     sx={{
//       color: '#00A3D8',
//       '&:hover': {
//         backgroundColor: 'rgba(0, 163, 216, 0.08)',
//       }
//     }}
//   >
//             <DownloadButton countSize={count} />
//           </StyledActionButton>
//         )} */}

//         <Box>
//           <KeywordLimit
//             AddKeyword={AddKeyword}
//             deleted={deleted}
//             loading={loading}
//             keywordData={keywordData}
//           />
//         </Box>
//       </Box>
//     </Box>

//     {/* Bottom Section - Results Count */}
//     <Box sx={{ 
//       mt: 2,
//       pt: 2,
//       borderTop: '1px solid rgba(145, 158, 171, 0.24)',
//       display: 'flex',
//       justifyContent: 'space-between',
//       alignItems: 'center'
//     }}>
//       <Typography variant="body2" color="text.secondary">
//         Showing {keywordData?.length || 0} of {count} keywords
//       </Typography>
//     </Box>
//   </Box>
// </StyledCard>

//       {/* {!loading ? (
//     <> */}

//       {/* <TableContainer
//         sx={{
//           height: "500px !important",
//           scrollBehavior: "smooth",
//           zIndex: 999,
//           overflowY: "scroll", // Use 'scroll' to enable scrolling
//           "&::-webkit-scrollbar": {
//             width: "0.2em", // Set the width of the scrollbar
//             backgroundColor: "white", // Hide the track of the scrollbar
//           },
//           "&::-webkit-scrollbar-thumb": {
//             backgroundColor: "#0db6f6",
//             borderRadius: "10px",
//           },
//         }}
//         component={Paper}
//       >
//         <Table key={refresh} stickyHeader={true} aria-label="collapsible table">
//           <TableHead>
//             <TableRow>
//               {selectedRows.length === 0 ? (
//                 <TableCell className="p1">{isCd ? null : "Select"}</TableCell>
//               ) : (
//                 <TableCell>
//                   <Iconify
//                     icon={"mdi:delete"}
//                     width={25}
//                     height={25}
//                     sx={{ cursor: "pointer" }}
//                     onClick={() => handleDeleteSelectedAlert()}
//                   />
//                 </TableCell>
//               )}
//               <TableCell className="p2">Keyword Url</TableCell>
//               <TableCell className="p3" align="right">
//                 Keyword
//               </TableCell>
//               <TableCell className="position" align="right" width={"150px"}>
//                 {" "}
//                 Position &nbsp;
//                 <Tooltip title="Sort" placement="top">
//                   <Iconify
//                     icon="solar:round-sort-vertical-bold"
//                     color="#00A3D8"
//                     width={18}
//                     height={15}
//                     onClick={handleSortClick}
//                   />
//                 </Tooltip>
//               </TableCell>

//               <TableCell align="right">1d</TableCell>
//               <TableCell align="right">7d</TableCell>
//               <TableCell align="right">1m</TableCell>
//               <TableCell align="center">Action</TableCell>
//               <TableCell align="center">Update</TableCell>
//             </TableRow>
//           </TableHead>
//           {/* <TableBody>
//             {keywordData?.map((row) => (
//               <Row
//                 key={row?.id}
//                 row={row}
//                 handleCheckboxChange={handleCheckboxChange}
//                 setDeleted={setDeleted}
//                 deleted={deleted}
//                 refresh={refresh}
//                 setKeywordData={setKeywordData}
//                 keywordData={keywordData}
//                 // Pass the handleDelete function as a prop
//               />
//             ))}
//           </TableBody> */}

// {/* <TableBody>
//   {loading ? (
//     <TableSkeleton />
//   ) : (
//     keywordData?.map((row) => (
//       <Row
//         key={row?.id}
//         row={row}
//         handleCheckboxChange={handleCheckboxChange}
//         setDeleted={setDeleted}
//         deleted={deleted}
//         refresh={refresh}
//         setKeywordData={setKeywordData}
//         keywordData={keywordData}
//       />
//     ))
//   )}
// </TableBody> */}
//         {/* </Table>
//         {keywordData?.length > 0 ? (
//           <>
//             <TablePagination
//               rowsPerPageOptions={[30, 100, 500, 1000]}
//               component="div"
//               count={count}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               onPageChange={handleChangePage}
//               onRowsPerPageChange={handleChangeRowsPerPage}
//             />
//           </>
//         ) : (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 marginTop: "10px",
//               }}
//             >
//               <strong>No Keyword found for this domain! </strong>
//             </div>
//             <div style={{ display: "flex", justifyContent: "center" }}>
//               <img
//                 alt="nothing found"
//                 src="/assets/nothing.jpg"
//                 width={"500px"}
//                 height={"380px"}
//               />
//             </div>
//           </>
//         )}
//       </TableContainer> */}
//       {/* </>
//       ) : (
//         <div style={{ display: 'flex', justifyContent: 'center' }}>
//           <CircularProgress />
//         </div>
//       )} */}
//   <StyledCard>

// <TableContainer
//       sx={{
//           height: "500px !important",
//           scrollBehavior: "smooth",
//           zIndex: 999,
//           overflowY: "scroll",
//           "&::-webkit-scrollbar": {
//             width: "0.2em",
//             backgroundColor: "white",
//           },
//           "&::-webkit-scrollbar-thumb": {
//             backgroundColor: "#0db6f6",
//             borderRadius: "10px",
//           },
//         }}
//     component={Paper}
//   >
//     <Table key={refresh} stickyHeader={true} aria-label="collapsible table">
//       <TableHead>
//         <TableRow>
//           {selectedRows.length === 0 ? (
//             <TableCell className="p1">{isCd ? null : "Select"}</TableCell>
//           ) : (
//             <TableCell>
//               <Iconify
//                 icon={"mdi:delete"}
//                 width={25}
//                 height={25}
//                 sx={{ cursor: "pointer" }}
//                 onClick={() => handleDeleteSelectedAlert()}
//               />
//             </TableCell>
//           )}
//           <TableCell className="p2">Keyword Url</TableCell>
//           <TableCell className="p3" align="right">
//             Keyword
//           </TableCell>
//           <TableCell className="position" align="right" width={"150px"}>
//             Position &nbsp;
//             <Tooltip title="Sort" placement="top">
//               <Iconify
//                 icon="solar:round-sort-vertical-bold"
//                 color="#00A3D8"
//                 width={18}
//                 height={15}
//                 onClick={handleSortClick}
//               />
//             </Tooltip>
//           </TableCell>
//           <TableCell align="right">1d</TableCell>
//           <TableCell align="right">7d</TableCell>
//           <TableCell align="right">1m</TableCell>
//           <TableCell align="center">Action</TableCell>
//           <TableCell align="center">Update</TableCell>
//         </TableRow>
//       </TableHead>

//       <TableBody>
//         {loading ? (
//           <TableSkeleton />
//         ) : keywordData?.length > 0 ? (
//           keywordData?.map((row) => (
//             <Row
//               key={row?.id}
//               row={row}
//               handleCheckboxChange={handleCheckboxChange}
//               setDeleted={setDeleted}
//               deleted={deleted}
//               refresh={refresh}
//               setKeywordData={setKeywordData}
//               keywordData={keywordData}
//             />
//           ))
//         ) : !loading && domainName?.id ? (
//           <TableRow>
//             <TableCell colSpan={9}>
//               <Box sx={{ textAlign: 'center', py: 3 }}>
//                 <Typography variant="h6" gutterBottom>
//                   No Keyword found for this domain!
//                 </Typography>
//                 <img
//                   alt="nothing found"
//                   src="/assets/nothing.jpg"
//                   width={"500px"}
//                   height={"380px"}
//                 />
//               </Box>
//             </TableCell>
//           </TableRow>
//         ) : null}
//       </TableBody>
//     </Table>

//     {!loading && keywordData?.length > 0 && (
//       <TablePagination
//         rowsPerPageOptions={[30, 100, 500, 1000]}
//         component="div"
//         count={count}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     )}
//   </TableContainer>
//   </StyledCard>
//     </Box>
//   );
// }







import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import { Pagination } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Stack,
  TablePagination,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactCountryFlag from "react-country-flag";
import KeywordLimit from "src/components/keywordsLimit/limitKeywords";
import Iconify from "../iconify/Iconify";
import KeywordHistory from "../keyword-history/keywordHistory";
import { MyContext } from "../../pages/DashboardAppPage";
import domain from "../../module/domain";
import keyword from "../../module/keyword";
import position from "../../module/position";
import DownloadButton from "../csv-download";
import AddKeyword from "../keyword/addKeyword";
import Auth from "../../module/Auth";
import "./collapse.css";


function createData(keyword, position, id, favourite, code) {
  return {
    keyword,
    position,
    id,
    favourite,
    code,
  };
}

function Row(props) {
  const {
    row,
    selectedRows,
    handleCheckboxChange,
    setDeleted,
    deleted,
    setKeywordData,
    keywordData,
    domainName,
    setCount,
    setRefresh,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(props?.row);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [sortDirection, setSortDirection] = React.useState("asc");

  useEffect(() => {}, [rowData, keywordData]);
  const handleReload = (id) => {
    if (!isCd) {
      setLoading(true);
      position.refreshPosition(id, (response) => {
        if (response.status === "success") {
          const data = {
            code: response.data?.code,
            favourite: response.data?.favourite,
            id: response.data?.id,
            keyword: response.data?.keyword,
            position: response.data?.position,
          };
          setRowData(data);
          setKeywordData((prev) => [...prev]);
          setRefresh((prev) => !prev);
          // if (open) {
          //   handleOtherLinksData(response.data?.id);
          // }

          setLoading(false);
        }
        setLoading(false);
      });
    }
  };


  const handleDelete = (rowId) => {
    if (!isCd) {
      keyword.deleteKeyword(rowId, (response) => {
        if (response.status === "success") {
          setDeleted(!deleted);
          setKeywordData((prevKeywordData) =>
            prevKeywordData.filter((row) => row.id !== rowId)
          );
        }
      });
    }
  };

  const handleAlert = (id) => {
    if (Auth?.isSubscribed()) {
      const res = window.confirm(
        "Are you sure you want to delete this keyword?"
      );
      if (res) {
        handleDelete(id); // Call handleDelete only if the user confirms
      }
    } else {
      window.alert("Delete option is not available for free account");
    }
  };
  const handleFavorite = (id, param) => {
    if (!isCd) {
      let fav;
      if (param === "yes") {
        fav = true;
      } else {
        fav = false;
      }
      const data = {
        favourite: fav,
      };
      keyword.updateKeyword(id, data, (response) => {
        setLoading(true);
        if (response.status === "success") {
          setRowData({ ...rowData, favourite: response.data.favourite });
          setLoading(false);
        }
        setLoading(false);
      });
    }
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);

  // Get the user's local time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Use the `utc` method to parse the ISO timestamp string and convert it to UTC time
  const utcTime = dayjs.utc(rowData?.position?.creationDate);

  // Use the `tz` method to convert the UTC time to the user's local time zone
  const localTime = utcTime.tz(userTimeZone);

  const handleEye = () => {
    if (rowData?.position?.url === null) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const isCd = Auth.getIsCd(); //

  return (
    <>
      <>
        {/* <Item> */}
        <TableRow className="table-row" key={rowData?.id}>
          <TableCell className="t1" key={rowData?.id}>
            <Box display={"flex"} flexDirection={"row"} m={0} p={0}>
            {Auth?.isSubscribed() && !isCd && (
                <Checkbox
                  checked={selectedRows && selectedRows.includes(row.id)}
                  onChange={(event) => handleCheckboxChange(event, row.id)}
                />
              )}
              <IconButton
                key={rowData?.id}
                aria-label="expand row"
                size="small"
              >
                <Iconify
                  onClick={handleEye}
                  icon={"mdi:eye"}
                  width={16}
                  height={16}
                />
              </IconButton>
            </Box>

            {open ? (
              <KeywordHistory
                rowData={rowData}
                position={rowData?.position?.position}
                // chartData={chartData}
                // otherLinks={otherLinks}
                open={open}
                handlePropClose={handleOpen}
              />
            ) : null}
          </TableCell>
          <TableCell
            scope="row"
            key={rowData?.id}
            sx={{ maxWidth: "500px", overflow: "clip" }}
          >
            <Link
              target={"_blank"}
              rel="noopener"
              href={rowData?.position?.url}
            >
              {rowData?.position?.url}
            </Link>
          </TableCell>
          <TableCell table-row align="right">
            {
              <>
                {rowData?.keyword} &nbsp;
                <ReactCountryFlag
                  countryCode={rowData.code}
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  cdnSuffix="svg"
                  title="flag"
                />
              </>
            }
          </TableCell>
          <TableCell
            className="table-row"
            align="right"
            style={{
              color:
                rowData?.position == null || rowData?.position?.position == null
                  ? "gray"
                  : "black",
            }}
          >
            {!loading ? (
              <>
                {rowData?.position !== null &&
                rowData?.position?.position !== null
                  ? rowData?.position?.position
                  : ">100"}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.dayStats != null &&
                rowData.position?.dayStats !== 0 ? (
                  <>
                    {rowData?.position?.dayStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.dayStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.weekStats != null &&
                rowData.position?.weekStats !== 0 ? (
                  <>
                    {rowData?.position?.weekStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.weekStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.monthStats != null &&
                rowData.position?.monthStats !== 0 ? (
                  <>
                    {rowData?.position?.monthStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.monthStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell className="action" align="right" key={rowData?.id}>
            <Box display={"flex"} flexDirection={"row"} marginLeft={3}>
              {rowData?.favourite ? (
                <>
                  <Tooltip title="Favorite">
                    <Iconify
                      icon={"uim:favorite"}
                      width={20}
                      height={18}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleFavorite(rowData?.id, "no")}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Add Favorite">
                    <Iconify
                      icon={"mi:favorite"}
                      width={20}
                      height={18}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleFavorite(rowData?.id, "yes")}
                    />
                  </Tooltip>
                </>
              )}

              <Tooltip title={"Refresh"}>
                <Iconify
                  icon={"ion:reload-circle"}
                  width={20}
                  height={18}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleReload(rowData?.id)}
                />
              </Tooltip>
              {!isCd && (
  <Tooltip
    title={
      !Auth?.isSubscribed() 
        ? "Delete option is disabled for free account"
        : "Delete"
    }
    placement="top"
  >
    <Iconify
      icon={"ic:baseline-delete"}
      width={20}
      height={18}
      sx={{
        cursor: Auth?.isSubscribed() ? "pointer" : "not-allowed",
        color: Auth?.isSubscribed() ? "#ff0000bf" : "gray",
      }}
      onClick={() => Auth?.isSubscribed() ? handleAlert(rowData?.id) : null}
    />
  </Tooltip>
)}
            </Box>
          </TableCell>

          <TableCell
            className="table-row"
            align="right"
            key={rowData?.position?.id}
          >
            <Typography
              align="center"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {rowData?.position !== null &&
              rowData?.position?.position !== null
                ? localTime.fromNow({ includeSeconds: true })
                : "-"}
            </Typography>
          </TableCell>
        </TableRow>
        {/* </Item> */}
      </>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    position: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    favourite: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {
  const {
    domainName,
    tableLoading,
    keywordData,
    setKeywordData,
    refresh,
    setRefresh,
  } = React.useContext(MyContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [page, setPage] = React.useState(0);
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [deviceName, setDeviceName] = React.useState("desktop");
  const [selected, setSelected] = React.useState(true);
  const [mobileSelected, setMobileSelected] = React.useState(false);
  const [desktopSelected, setDesktopSelected] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [sortAscending, setSortAscending] = React.useState(true);

  React.useEffect(() => {
    if (domainName?.id) getDomainById(page, deviceName);
  }, [
    domainName,
    tableLoading,
    rowsPerPage,
    page,
    deviceName,
    deleted,
    refresh,
  ]);

  const getDomainById = (pagenum, deviceName) => {
    setLoading(true);

    domain.getByDomainId(
      domainName?.id,
      page,
      rowsPerPage,
      deviceName,
      (response) => {
        if (response.status === "success") {
          const totalRows = response.data.totalRows;
          const rows = response.data.keywordDetails?.map((item) => {
            return createData(
              item?.keyword,
              item?.position,
              item?.id,
              item?.favourite,
              item?.code
            );
          });

          setCount(totalRows);
          setKeywordData(rows);

          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          setLoading(false);

          setKeywordData([]);
        }
      }
    );
  };

  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;

  const handleSortClick = () => {
    setSortAscending(!sortAscending);
    sortData();
  };

  const sortData = () => {
    const sortedData = [...keywordData].sort((a, b) => {
      const positionA = a.position ? a.position.position : null;
      const positionB = b.position ? b.position.position : null;

      if (positionA === null && positionB === null) {
        return 0;
      }
      if (positionA === null) {
        return 1;
      }
      if (positionB === null) {
        return -1;
      }
      if (sortAscending) {
        return positionA - positionB;
      } else {
        return positionB - positionA;
      }
    });
    setKeywordData(sortedData);

    const filteredData = sortedData?.slice(startIndex, endIndex);
  };

  // const totalRows = React.useMemo(() => getDomainById(page, deviceName), [page, deviceName]);
  // console.log(totalRows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage); // Calculate the new page based on the updated rowsPerPage.

    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleRemove = () => {
    setDeleted(!deleted);
  };

  const handleDeleteAllAlert = () => {
    if (!Auth?.isSubscribed()) {
      window.alert("Delete option is not available for free account");
    } else {
      const res = confirm("Are you sure, you want to delete all keywords");
      if (res) {
        handleDeleteAll();
      }
    }
  };

  const handleDeleteAll = () => {
    setLoading(true);
    keyword.deleteAllKeywords(domainName?.id, (response) => {
      if (response.status === "success") {
        setKeywordData([]);
        getDomainById(0, "desktop");
      }
    });
    setLoading(false);
  };

  const handleRefreshAll = () => {
    if (!isCd) {
      setLoading(true);
      const ids = keywordData.map((data) => data.id);
      const chunkSize = 20; // Number of IDs to send per API request
      const totalChunks = Math.ceil(ids.length / chunkSize); // Total number of chunks

      const sendChunk = (chunkIndex) => {
        const start = chunkIndex * chunkSize;
        const end = start + chunkSize;
        const chunkIds = ids.slice(start, end);

        try {
          keyword.reloadAllKeywords(chunkIds, (response) => {
            const data = response.data;
            if (response.status === "success") {
              setLoading(false);
              // const totalRows = response.data.totalRows;
              setRefresh(!refresh);
              const updatedRows = response.data;

              setKeywordData((prevData) => {
                // Update the keywordData by replacing the relevant chunks
                return prevData.map((item) => {
                  if (updatedRows.find((row) => row.id === item.id)) {
                    return updatedRows.find((row) => row.id === item.id);
                  } else {
                    return item;
                  }
                });
              });
            }
          });

          //   const ro=   data?.map((item) => {
          //        return createData(item?.keyword, item?.position, item?.id, item?.favourite, item?.code);
          //      });
          //      setKeywordData(ro);
          //    }

          //  });

          // setKeywordData((prevData) => {
          //   // Update the keywordData by replacing the relevant chunks
          //   const newData = [...prevData];
          //   chunkIds.forEach((id, index) => {
          //     const dataIndex = start + index;
          //     newData[dataIndex] = response.data.find((item) => item.id === id);
          //   });

          //   return newData;

          // });

          if (chunkIndex < totalChunks - 1) {
            setTimeout(() => sendChunk(chunkIndex + 1), 1000); // Send the next chunk after a short delay
          }
        } catch (error) {
          setLoading(false);
        }
      };

      sendChunk(0);
    }
  };

  const handleDeviceChange = (device) => {
 
    setDeviceName(device);
    if (device === "desktop") {
      setSelected(true); 
      setMobileSelected(false);
    } else {
      setSelected(false);
      setMobileSelected(true);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = keywordData.map((row) => row.id);
      setSelectedRows(newSelected);
    } else {
      selectedRows([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const handleDeleteSelectedAlert = () => {
    const res = confirm("Are you sure, you want to delete selected keywords");
    if (res) {
      handleDeleteSelected(selectedRows);
    }
  };

  const handleDeleteSelected = (selectedRows) => {
    setLoading(true);

    selectedRows.forEach((rowId) => {
      keyword.deleteKeyword(rowId, (response) => {
        if (response.status === "success") {
          setDeleted(!deleted);
          setKeywordData((prevKeywordData) =>
            prevKeywordData.filter((row) => row.id !== rowId)
          );
        }
      });
    });

    setSelectedRows([]);
    setLoading(false);
  };

  const onSelect = (keywordId) => {
    const isSelected = selectedRows.some((row) => row.id === keywordId);
    let newSelected = [];

    if (!isSelected) {
      const selectedKeyword = keywordData.find((row) => row.id === keywordId);
      newSelected = [...selectedRows, selectedKeyword];
    } else {
      newSelected = selectedRows.filter((row) => row.id !== keywordId);
    }

    setSelectedRows(newSelected);
  };

  const isCd = Auth.getIsCd(); //

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          
        <ButtonGroup variant="outlined" aria-label="split button" sx={{
          marginBottom:2,
        }}>
  <Tooltip title={"Device Desktop"}>
    <Button
      onClick={() => handleDeviceChange("desktop")}
      variant={selected ? "contained" : "outlined"}
      sx={{
        borderRadius: 5,
        backgroundColor: selected ? "#2065D1" : "white",
        color: selected ? "white" : "#2065D1",
        borderColor: "#2065D1",
        '&:hover': {
          backgroundColor: selected ? "#2065D1" : "white",
          color: selected ? "white" : "#2065D1",
          borderColor: "#2065D1",
        },
      }}
    >
      <Iconify icon={"material-symbols:desktop-mac-outline"} />
    </Button>
  </Tooltip>
  <Tooltip title={"Device Mobile"}>
    <Button
      variant={mobileSelected ? "contained" : "outlined"}
      onClick={() => handleDeviceChange("mobile")}
      sx={{
        borderRadius: 5,
        backgroundColor: mobileSelected ? "#2065D1" : "white",
        color: mobileSelected ? "white" : "#2065D1",
        borderColor: "#2065D1",
        '&:hover': {
          backgroundColor: mobileSelected ? "#2065D1" : "white",
          color: mobileSelected ? "white" : "#2065D1",
          borderColor: "#2065D1",
        },
      }}
    >
      <Iconify icon={"mdi:mobile-phone"} />
    </Button>
  </Tooltip>

</ButtonGroup>

          <Grid item display={"flex-start"}>
            <KeywordLimit
              AddKeyword={AddKeyword}
              deleted={deleted}
              loading={loading}
              keywordData={keywordData}
            />
          </Grid>
        </Grid>
        {keywordData?.length > 0 && (
          <Grid item xs>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {!isCd && (
                <Grid item>
                  <Tooltip title={"Delete All"} placement="top">
                    <Iconify
                      icon={"uiw:delete"}
                      width={25}
                      height={25}
                      sx={{
                        cursor: "pointer",
                        color: "#2065D1",
                      }}
                      onClick={() => handleDeleteAllAlert()}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <Tooltip title={"Refresh All"} placement="top">
                  {!loading ? (
                    <Iconify
                      icon={"fa:refresh"}
                      width={25}
                      height={25}
                      sx={{
                        cursor: "pointer",
                        color: "#2065D1",
                      }}
                      onClick={() => handleRefreshAll()}
                    />
                  ) : (
                    <>
                      <CircularProgress size={18} />
                    </>
                  )}
                </Tooltip>
              </Grid>
              {!isCd && !Auth?.isSubscribed() ? (
                <>
                  <Grid item>
                    <DownloadButton countSize={count} />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* {!loading ? (
    <> */}

{loading ? (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="500px"
  >
    <CircularProgress />
  </Box>
) : (
  <TableContainer
    sx={{
      height: "500px !important",
      scrollBehavior: "smooth",
      zIndex: 999,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "0.2em",
        backgroundColor: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0db6f6",
        borderRadius: "10px",
      },
    }}
    component={Paper}
  >
      <TableContainer
  sx={{
    height: "500px !important",
    scrollBehavior: "smooth",
    zIndex: 999,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.2em",
      backgroundColor: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0db6f6",
      borderRadius: "10px",
    },
  }}
  component={Paper}
>



<Table key={refresh} stickyHeader={true} aria-label="collapsible table">
  <TableHead sx={{ backgroundColor: "#F4F6F8" }}>
    <TableRow
      sx={{
        backgroundColor: "#F4F6F8",
        "& .MuiTableCell-root": {
          color: "rgb(99, 115, 129)",
          backgroundColor: '#F4F6F8',
        },
      }}
    >
      {selectedRows.length === 0 ? (
        <TableCell className="p1" sx={{ color: "white", }}>
          {isCd ? null : "Select"}
        </TableCell>
      ) : (
        <TableCell sx={{ backgroundColor: 'black' }}>
          <Iconify
            icon={"mdi:delete"}
            width={25}
            height={25}
            sx={{ cursor: "pointer", color: "rgb(99, 115, 129)"}}
            onClick={() => handleDeleteSelectedAlert()}
          />
        </TableCell>
      )}
      <TableCell className="p2" sx={{ color: "white", backgroundColor: 'black' }}>
        Keyword Url
      </TableCell>
      <TableCell className="p3" align="right" sx={{ color: "white", backgroundColor: 'black' }}>
        Keyword
      </TableCell>
      <TableCell
        className="position"
        align="right"
        width={"150px"}
        sx={{ color: "white", backgroundColor: 'black' }}
      >
        Position &nbsp;
        <Tooltip title="Sort" placement="top">
          <Iconify
            icon="solar:round-sort-vertical-bold"
            color="black"
            width={18}
            height={15}
            onClick={handleSortClick}
          />
        </Tooltip>
      </TableCell>
      <TableCell align="right" sx={{ color: "white", backgroundColor: 'black' }}>
        1D
      </TableCell>
      <TableCell align="right" sx={{ color: "white", backgroundColor: 'black' }}>
        7D
      </TableCell>
      <TableCell align="right" sx={{ color: "white", backgroundColor: 'black' }}>
        1M
      </TableCell>
      <TableCell align="center" sx={{ color: "white", backgroundColor: 'black' }}>
        Action
      </TableCell>
      <TableCell align="center" sx={{ color: "white", backgroundColor: 'black' }}>
        Update
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {keywordData?.map((row) => (
      <Row
        key={row?.id}
        row={row}
        handleCheckboxChange={handleCheckboxChange}
        setDeleted={setDeleted}
        deleted={deleted}
        refresh={refresh}
        setKeywordData={setKeywordData}
        keywordData={keywordData}
      />
    ))}
  </TableBody>
</Table>




</TableContainer>
    {keywordData?.length > 0 ? (
      <>
        <TablePagination
          rowsPerPageOptions={[30, 100, 500, 1000]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    ) : (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-450px",
          }}
        >
          <strong>No Keyword found for this domain! </strong>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="nothing found"
            src="/assets/nothing.jpg"
            width={"500px"}
            height={"380px"}
          />
        </div>
      </>
    )}
  </TableContainer>
)}

      {/* </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )} */}
    </Box>
  );
}















